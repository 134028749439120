import React, { Component } from 'react';
import { BrowserRouter as Router , Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import axios from 'axios';
import { createBrowserHistory } from 'history';
 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

toast.configure()

const history = createBrowserHistory();

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

axios.defaults.baseURL = 'https://api.ontoespub.com';
axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken');

axios.interceptors.response.use((response) => response, (error) => {
  if (error && error.response && error.response.status === 401) {
    localStorage.removeItem('accessToken');
    window.location = 'localhost:3000';
  }
  return error;
});


class App extends Component {
  Logout() {

    localStorage.removeItem('accessToken');  
    return null;
  }
  render() {
    const authenticated = localStorage.getItem('accessToken');
    return (
      <Router >
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route  path= '/logout' name= 'Logout' render={() => {
                      this.Logout();
                      history.push({pathname:'/admin/login'})
                      history.go();
                    }} />
              {
                authenticated && <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
              }
              <Route  path="/login" exact name="Login Page" component={Login} />
              {!authenticated && <Route
                    render={() => {
                      history.push({pathname:'/login'})
                      history.go('/login');
                      // return null;
                    }}
                  />
}

              

            </Switch>
          </React.Suspense>
      </Router >
    );
  }
}

export default App;
